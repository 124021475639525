<template>
  <router-link
    :to="href"
    class="animation"
    :class="['block-link', classList]"
    v-if="isRoute"
    v-observe-visibility="animation"
  >
    <slot></slot>
  </router-link>
  <a
    :href="href"
    class="animation"
    :class="['block-link', classList]"
    v-if="!isRoute"
    v-observe-visibility="animation"
  >
    <slot></slot>
  </a>
</template>

<script>
import { animationMixin } from '@/components/mixins/animation';

export default {
  name: 'BlockLink',
  mixins: [animationMixin],
  props: {
    href: String,
    isRoute: Boolean,
    classList: String,
  },
};
</script>

<style lang="scss">
.block-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @include wRule(
    font-size,
    (
      xl: 20,
      lg: 18,
      md: 16,
      sm: 30,
    )
  );

  span {
    position: relative;
    display: inline-block;

    &::before {
      @include pseudo;

      font-size: inherit;
      left: 0;
      bottom: -0.04em;
      width: 100%;
      height: 0.1em;
      background-color: currentColor;
      transition: transform 0.4s ease;
      transform: scaleX(0);
      transform-origin: 0 50%;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
  }

  .icon {
    display: block;

    &_arrow {
      @include wRule(
        height,
        (
          xl: 18,
          lg: 18,
          md: 15,
          sm: 18,
        )
      );
      @include wRule(
        width,
        (
          xl: 79,
          lg: 79,
          md: 71,
          sm: 79,
        )
      );

      &:not(:first-child) {
        @include wRule(
          margin-left,
          (
            xl: 30,
            lg: 35,
            md: 28,
            sm: 52,
          )
        );
      }
    }
  }

  &:hover {
    span {
      &::before {
        transform: scaleX(1);
      }
    }
  }

  &_large {
    box-sizing: border-box;
    z-index: 3;
    background-color: #fff;
    background: rgba(0, 0, 0, 0.48);
    transition: background $ease-main;
    backdrop-filter: blur(11px);

    @include wRule(
      padding,
      (
        xl: pxtovh(80,xl) pxtovw(72,xl),
        lg: pxtovh(45,lg) pxtovw(90,lg),
        md: pxtovh(51,md) pxtovw(48,md),
        sm: 85 53,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 18,
        md: 15,
        sm: 30,
      )
    );

    span {
      &::before {
        display: none;
      }
    }

    .icon {
      &_arrow {
        @include wRule(
          height,
          (
            xl: 18,
            lg: 18,
            md: 8,
            sm: 18,
          )
        );
        @include wRule(
          width,
          (
            xl: 60,
            lg: 45,
            md: 31,
            sm: 79,
          )
        );

        &:not(:first-child) {
          @include wRule(
            margin-left,
            (
              xl: 25,
              lg: 25,
              md: 28,
              sm: 52,
            )
          );
        }
      }
    }

    &:hover {
      background-color: color(dark);
    }

    &.animation {
      opacity: 0;
      transform: translate3d(20px, 0, 0);
    }
  }

  &.animation {
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease, background 0.3s ease;

    span {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    .icon {
      transform-origin: 0 50%;
      opacity: 0;
      transform: scaleX(0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }
  }

  &.is-animated {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.5s ease 0.7s, transform 0.5s ease 0.7s,
      background 0.3s ease;

    span {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease 0.8s, transform 0.5s ease 0.8s;
    }

    .icon {
      opacity: 1;
      transform: scaleX(1);
      transition: opacity 0.5s ease 0.9s, transform 0.5s ease 0.9s;
    }
  }
}
</style>
